<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-editable="blok"
      :class="{
        'c-rich-text--light':
          pageSectionTheme === 'light' ||
          pageSectionTheme === 'white' ||
          pageSectionTheme === 'muted',
        'c-rich-text--dark': pageSectionTheme === 'dark',
      }"
      class="c-rich-text"
      v-html="visibleContent"
    />
    <!-- eslint-enable -->
    <div v-if="hasReadMore" class="mt-4">
      <UiToggleButton v-model="readMoreOpen" />
    </div>
  </div>
</template>

<script setup lang="ts">
import screens from '#tailwind-config/theme/screens'

import type {
  BlockRichTextStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'

const props = defineProps < {
  blok: BlockRichTextStoryblok
  readMoreOverride?: Boolean
  pageSectionTheme: PageSectionStoryblok['theme']
}>()

const readMoreOpen = ref(false)
const defaultLength = ref<number>(50)
const viewportWidth = ref(0);

const setViewportWidth = () => {
  viewportWidth.value = window.innerWidth;
}

const isMobileViewport = computed(() => viewportWidth.value < stripPx(screens.lg))

const trimLength = computed(() => {
  // required due to SB number field being typed as string
  return props.blok?.readMoreLength
    ? parseInt(props.blok.readMoreLength)
    : defaultLength.value
})

const trimLengthLarge = computed(() => {
  // required due to SB number field being typed as string
  return props.blok?.readMoreLengthLarge
    ? parseInt(props.blok.readMoreLengthLarge)
    : trimLength.value
})

const wordCount = computed(() => {
  return isMobileViewport.value
    ? trimLength.value
    : trimLengthLarge.value
})

const content = computed(() => {
  return renderRichText(props.blok.content)
})

const contentArray = computed(() => {
  return content.value.split(' ')
})

const hasReadMore = computed(() => {
  return (props.blok?.readMore || props.readMoreOverride)
    && contentArray.value.length > wordCount.value
})

const visibleContent = computed(() => {
  if (!hasReadMore.value || readMoreOpen.value || !wordCount.value) {
    return content.value
  }
  return content.value.split(' ').slice(0, wordCount.value).join(' ') + '…'
})

onMounted(() => {
  setViewportWidth();
  window.addEventListener('resize', setViewportWidth);
});
onUnmounted(() => {
  window.removeEventListener('resize', setViewportWidth);
});
</script>

<style scoped lang="postcss">
.c-rich-text {
  :deep() {
    > * + * {
      margin-block-start: 1.125rem;
    }

    h2 {
      @apply text-lg font-bold;

      .display {
        @apply font-display lg:text-2xl;
      }
    }

    h3 {
      @apply text-base font-bold;
    }

    h4 {
      @apply text-sm font-bold;
    }

    * + {
      h2,
      h3,
      h4 {
        margin-block-start: 3.5rem;
      }
    }

    ul {
      @apply list-disc pl-4;

      li {
        @apply mb-4;
      }
    }

    ol {
      @apply list-inside list-decimal;
    }

    .checklist {
      @apply flex border-t bg-no-repeat pl-10 pt-4;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill='%2300843D' d='m10 13.6 5.9-5.9a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-6.6 6.6c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3l-2.6-2.6a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.092.7.275l1.9 1.9Z'/%3E%3C/svg%3E");
      background-position: top 1rem left 4px;
    }

    blockquote {
      @apply border-primary-500 text-primary-500 font-display my-12 -ml-5 border-l-[6px] bg-no-repeat pl-9 text-xl leading-tight sm:ml-0 lg:pl-[4.5rem] lg:text-[1.6875rem];
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12' fill='none'%3E%3Cpath fill='%2300843D' d='M6.88.68v1.96c-1.72.48-3.12 1.16-3.36 3.12.4-.28.88-.28 1.32-.28 1.52 0 2.48 1.12 2.48 2.6 0 2.2-1.2 3.52-3.44 3.52-2.28 0-3.6-1.96-3.6-4.08 0-4.04 2.88-6.24 6.6-6.84Zm10.4 0v1.96c-1.72.48-3.12 1.16-3.36 3.12.4-.28.88-.28 1.32-.28 1.52 0 2.48 1.12 2.48 2.6 0 2.2-1.2 3.52-3.44 3.52-2.28 0-3.6-1.96-3.6-4.08 0-4.04 2.88-6.24 6.6-6.84Z'/%3E%3C/svg%3E");
      background-position: top 0 left 0.875rem;

      @screen lg {
        background-position: top 0 left 2rem;
      }
    }

    a {
      @apply text-primary-500 underline;
    }
  }

  &--light {
    h2,
    h3,
    h4 {
      @apply text-gray-900;
    }

    > *:not(h2, h3, h4) {
      @apply text-secondary;
    }

    :deep(.checklist) {
      @apply border-black/10;
    }
  }

  &--dark {
    h2,
    h3,
    h4 {
      @apply text-white;
    }

    > *:not(h2, h3, h4) {
      @apply text-gray-300;
    }

    :deep(.checklist) {
      @apply border-white;
    }
  }
}
</style>
